<template>
  <div class="wap-shop-car">
    <headerBar
      :title="$t('dian-pu-zhi-tong-che-0')"
      @back="back"
      :rightText="$t('gou-mai-ji-lu')"
    ></headerBar>

    <div class="car-list">
      <div class="list flex" v-for="(item, i) in carList" :key="i">
        <div class="img">
          <img :src="item.icon" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="name">{{ item.name }}</div>
          <div class="count">
            {{ $t('ke-tui-guang-chan-pin-shu') }}<span class="blue">{{
              item.count
            }}</span>
          </div>
          <div class="desc">{{ item.desc }}</div>
          <div class="flex-center-between mt-10">
            <div class="price">
              ${{ item.price }}
              <span class="small">
                /10
                {{ $t('tian-0') }}
              </span>
            </div>
            <Button>{{ $t('li-ji-gou-mai') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Button } from 'vant'
import { refundList } from '@/api/shop'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      carList: [
        {
          icon: require('@/assets/imgs/car1.png'),
          name: this.$t('guo-ji-tui-guang'),
          count: 10,
          desc: this.$t(
            'quan-dian-tou-fang-qiang-dao-liu-liang-jiu-shi-zhuan-dao-bao-bei-huo-qu-liu-liang-geng-gao-xiao-chi-xu-tou-fang'
          ),
          price: 1088,
          limit: 10
        },
        {
          icon: require('@/assets/imgs/car2.png'),
          name: this.$t('hai-wai-tui-guang'),
          count: 5,
          desc: this.$t(
            'liu-liang-shen-qi-que-ding-pu-guang-500-rang-bao-bei-kuai-su-huo-de-que-ding-xing-liu-liang'
          ),
          price: 588,
          limit: 5
        },
        {
          icon: require('@/assets/imgs/car1.png'),
          name: this.$t('guo-ji-tui-guang-0'),
          count: 2,
          desc: this.$t(
            'yi-liu-liang-jing-zhun-fu-chi-tou-fang-quan-fang-wei-hu-hang'
          ),
          price: 288,
          limit: 3
        }
      ]
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>